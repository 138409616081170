import Vue from 'vue'
// @ts-ignore
import numeral from 'numeral'
import { get } from 'lodash-es'
import 'numeral/locales/fr-ca'

export default Vue.extend({
  computed: {
    locale () {
      return get(this, '$i18n.locale') ? get(this, '$i18n.locale') : 'fr'
    },
    moneyFormat () {
      // @ts-ignore
      return this.locale === 'fr' ? '0,0.00 $' : '$ 0,0.00'
    },
    numberFormat () {
      // @ts-ignore
      return this.locale === 'fr' ? '0,0[.]00' : '0,0[.]00'
    }
  },
  methods: {
    $money (value: any, roundValue = false) {
      if (value === undefined || value === null) return ''
      const valueToFormat = roundValue ? Math.round(Number(value)) : Number(value)
      return numeral(valueToFormat).format(this.moneyFormat)
    },
    $number (value: any, roundValue = false) {
      if (value === undefined || value === null) return ''
      const valueToFormat = roundValue ? Math.round(Number(value)) : Number(value)
      return numeral(valueToFormat).format(this.numberFormat)
    }
  },
  created () {
    if (this.locale === 'fr') {
      numeral.locale('fr-ca')
    }
  },
  watch: {
    locale: (value) => {
      if (value === 'fr') {
        numeral.locale('fr-ca')
      } else {
        numeral.locale('en')
      }
    }
  }
})
