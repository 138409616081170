<template>
  <div class="d-flex flex-wrap border-top d-flex justify-content-center align-items-center">
    <ul class="pagination my-3">
      <li class="page-item" :class="{ disabled: !isPrevEnabled }">
        <a class="page-link" href="" aria-label="Previous" @click.prevent="handlePrev">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <template v-for="(page, i) in visiblePages">
        <li :key="`page-${i}`" class="page-item" :class="{ active: page === currentPage, disabled: page === '...' }">
          <a class="page-link" href="" @click.prevent="handlePage(page)">{{ page }}</a>
        </li>
      </template>
      <li class="page-item" :class="{ disabled: !isNextEnabled }">
        <a class="page-link" href="" aria-label="Next" @click.prevent="handleNext">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
    <div class="ml-0 ml-md-3 mb-3 mb-md-0">
      <b-form-select v-if="displayPerPage"
                     class="border-0"
                     v-model="perPage"
                     :options="options"
                     size="sm"
                     @change="handlePage(1)" />
    </div>
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'

const getPageRange = (current, last, width = 2) => {
  const left = current - width
  const right = current + width
  const range = []
  const rangeWithDots = []
  let rangeIndex

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i <= right)) {
      range.push(i)
    } else if (i < left) {
      i = left - 1
    } else if (i > right) {
      range.push(last)
      break
    }
  }

  range.forEach((i) => {
    if (rangeIndex) {
      if (i - rangeIndex === 2) {
        rangeWithDots.push(rangeIndex + 1)
      } else if (i - rangeIndex !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    rangeIndex = i
  })

  return rangeWithDots
}

export default {
  mixins: [pagination],
  props: {
    state: {
      type: String,
      required: true
    },
    pages: {
      type: Number,
      required: false,
      default: 0
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    range: {
      type: Number,
      required: false,
      default: 3
    },
    displayPerPage: {
      type: Boolean,
      default: false
    },
    listPerPage: {
      type: Array,
      default: () => ['10', '20', '40', '80', '100', '200']
    }
  },
  computed: {
    visiblePages () {
      return getPageRange(Number(this.currentPage), Number(this.pages), Number(this.range))
    },
    isPrevEnabled () {
      return Number(this.currentPage) > 1
    },
    isNextEnabled () {
      return Number(this.currentPage) < Number(this.pages)
    },
    options () {
      return this.listPerPage.map(page => ({ value: page, text: `${page} par page` }))
    }
  },
  methods: {
    handlePage (page) {
      this.$emit('change', page)
    },
    handlePrev () {
      if (this.isPrevEnabled) {
        this.$emit('change', Number(this.currentPage) - 1)
      }
    },
    handleNext () {
      if (this.isNextEnabled) {
        this.$emit('change', Number(this.currentPage) + 1)
      }
    }
  },
  created () {
    this.instance = this.state
  }
}
</script>
