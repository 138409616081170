<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="form-inline">
              <div v-if="displayTextFilter" class="form-group mt-1 mobile-wid-100">
                <input type="text"
                       class="form-control quickfilter-input mobile-wid-100"
                       :placeholder="placeholderTextFilter"
                       @change="changePage(1)"
                       v-model="textFilter">
              </div>
              <div v-if="displayTextFilter" class="ml-2 hidden-md"></div>

              <div v-if="displayTypeFilter" class="form-group mt-1 mobile-wid-100">
                <select class="custom-select mobile-wid-100" @change="changePage(1)" v-model="typeFilter">
                  <option value="">Type</option>
                  <option v-for="item in listTypeFilter" :key="item.value" :value="item.value">{{ item.label }}</option>
                </select>
              </div>
              <div v-if="displayTypeFilter" class="ml-2 hidden-md"></div>

              <div v-if="displayStatusFilter" class="form-group mt-1 mobile-wid-100">
                <select class="custom-select mobile-wid-100" @change="changePage(1)" v-model="statusFilter">
                  <option value="">Statut</option>
                  <option v-for="item in listStatusFilter" :key="item.value" :value="item.value">{{ item.label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-inline">
            <div class="ml-2 hidden-md"></div>
            <div v-if="displayReset && hasDefinedFilters" class="form-group mt-1 mobile-wid-100">
              <button class="btn btn-link btn-sm" @click.prevent="reset">
                Réinitialiser les filters <fa-icon class="ml-1" :icon="['fas', 'times']" size="xs"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'

export default {
  mixins: [pagination],
  props: {
    state: {
      type: String,
      required: true
    },
    displayTextFilter: {
      type: Boolean,
      default: false
    },
    placeholderTextFilter: {
      Type: String,
      default: ''
    },
    displayStatusFilter: {
      type: Boolean,
      default: false
    },
    listStatusFilter: {
      type: Array,
      default: () => []
    },
    displayTypeFilter: {
      type: Boolean,
      default: false
    },
    listTypeFilter: {
      type: Array,
      default: () => []
    },
    displayReset: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasDefinedFilters () {
      return this.textFilter.trim().length > 0 || this.typeFilter || this.statusFilter
    }
  },
  methods: {
    reset () {
      if (this.displayTextFilter) {
        this.textFilter = ''
      }

      if (this.displayStatusFilter) {
        this.statusFilter = ''
      }

      if (this.displayTypeFilter) {
        this.typeFilter = ''
      }

      this.sortBy = ''
      this.sortDirection = 'DESC'
      this.changePage(1)
      this.$toasted.info('Les filtres sont réinitialisés')
    },
    changePage (page) {
      this.$emit('changePage', page)
    }
  },
  created () {
    this.instance = this.state
  }
}
</script>
