import Vue, { VueConstructor } from 'vue'

export default (Vue as VueConstructor<Vue & InstanceType<any>>).extend({
  data () {
    return {
      instance: ''
    }
  },
  computed: {
    isFetching () {
      return this.$store.state[this.instance].isFetching
    },
    isLoading () {
      return this.$store.state[this.instance].isLoading
    },
    paginatedData () {
      return this.$store.state[this.instance].paginatedData
    },
    page () {
      return this.$store.state[this.instance].page
    },
    textFilter: {
      get () {
        return this.$store.state[this.instance].textFilter
      },
      set (value) {
        this.$store.commit(`${this.instance}/SET_TEXT_FILTER`, value)
      }
    },
    typeFilter: {
      get () {
        return this.$store.state[this.instance].typeFilter
      },
      set (value) {
        this.$store.commit(`${this.instance}/SET_TYPE_FILTER`, value)
      }
    },
    perPage: {
      get () {
        return this.$store.state[this.instance].perPage
      },
      set (value) {
        this.$store.commit(`${this.instance}/SET_PER_PAGE`, value)
      }
    },
    statusFilter: {
      get () {
        return this.$store.state[this.instance].statusFilter
      },
      set (value) {
        this.$store.commit(`${this.instance}/SET_STATUS_FILTER`, value)
      }
    },
    sortDirection: {
      get () {
        return this.$store.state[this.instance].sortDirection
      },
      set (value) {
        this.$store.commit(`${this.instance}/SET_SORT_DIRECTION`, value)
      }
    },
    sortBy: {
      get () {
        return this.$store.state[this.instance].sortBy
      },
      set (value) {
        this.$store.commit(`${this.instance}/SET_SORT`, value)
      }
    }
  }
})
